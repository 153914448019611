const Theme = {
  boxShadow: "2px 2px 10px 0px rgba(45,45,48,0.3);",
  darkBoxShadow: "2px 2px 10px 0px rgba(45,45,48,0.5);",
  colors: {
    default: "#2c2d30",
    secondary: "#737E90",
    light: "#f5f7f9",
    lightBlue: "#ecf1fb",
    border: "#dddddd",
    borderLight: "#E6EAEE",
    borderHighlighted: "#c4c4c4",
    borderDark: "#bbbbbb",
    borderOutline: "#e0e8ff",
    primaryBlue: "#477be4",
    darkBlue: "#0074f3",
    vibrantBlue: "#3760d9",
    green: "#84ce7b",
    brightGreen: "#5cd24d",
    darkGreen: "#77bb6e",
    gray: "#737e90",
    lightGray: "#d1d5db",
    red: "#ff5754",
    dark: "#3c3d42",
    shadow: "rgba(44, 45, 48, 0.3)",
    greyOverlay: "rgba(44, 45, 48, 0.6)",
    transparentGreyBackground: "rgba(0, 0, 0, 0.4)",
    transparentGreyBackgroundHover: "rgba(0, 0, 0, 0.6)",
    chat: {
      sent: "#007AFF",
      received: "#F1F2F6",
    },
  },
  fonts: {
    primary: "'Proxima Nova Regular', sans-serif",
    bold: "'Proxima Nova Bold', sans-serif",
    semiBold: "'Proxima Nova Semi Bold', sans-serif",
  },
  fontSize: {
    small: "12px",
    normal: "14px",
    large: "16px",
    extraLarge: "21px",
  },
};

export default Theme;
