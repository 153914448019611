import styled, { css } from "styled-components";
import variants from "./variants";

const Button = styled.button`
  display: inline-block;
  color: white;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 3px;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-weight: normal;
  transition: all 0.2s ease-in-out;
  background: ${({ theme }) => theme.colors.primaryBlue};
  color: white;
  border: none;

  &:hover {
    background: ${({ theme }) => theme.colors.darkBlue};
  }

  font-size: ${({ thin, theme }) => (thin ? theme.fontSize.small : "14px")};
  min-width: ${({ thin }) => (thin ? "auto" : "125px")};
  padding: ${({ thin }) => (thin ? "5px 12px" : "12px")};
  width: ${({ full }) => (full ? "100%" : "auto")};
  ${({ thin }) =>
    thin &&
    css`
      white-space: nowrap;
    `}
  @media (max-width: 576px) {
    ${({ retainWidthOnMobile }) =>
      !retainWidthOnMobile &&
      css`
        width: 100%;
      `};
  }

  outline: none;

  &:active {
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: default;
    `};

  ${({ working }) =>
    working &&
    css`
      opacity: 0.75;
    `};
  ${({ inline }) =>
    inline &&
    css`
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0px;
    `};
  ${({ variant }) => variant && variants[variant]}
`;

export default Button;
