'use strict';

import querystring from 'querystring';
import axios from 'axios';

const restrictors = [
    {
        configVariable: 'viewingCountryCode',
        navProperty: 'country_code',
    },
    {
        configVariable: 'brand',
        navProperty: 'brand',
    },
    {
        configVariable: 'profileHealthRole',
        navProperty: 'health_role',
    },
    {
        configVariable: 'profileStatus',
        navProperty: 'profile_status',
    },
    {
        configVariable: 'hasSessions',
        navProperty: 'has_sessions',
    },
];

const envToBaseURLs = ({ env = '', brand = '' }) => {
    const shortEnv = env.startsWith('dev') ? 'dev' : env;

    const mapping = {
        dev: {
            memberUrl: `https://member.${env}.aws.${brand}.com`,
            communityUrl: `https://community.${env}.aws.${brand}.com/rms`,
            sessionsUrl: 'https://dev.sessions.psychologytoday.com/dashboard',
            copyBaseUrl: 'https://d3toog9rn0qxo0.cloudfront.net',
            navDefinitionBaseUrl: 'https://d2yovri7aiwgx3.cloudfront.net',
        },
        qa02: {
            memberUrl: `https://member.${env}.aws.${brand}.com`,
            communityUrl: `https://community.${env}.aws.${brand}.com/rms`,
            sessionsUrl: 'https://qa.sessions.psychologytoday.com/dashboard',
            copyBaseUrl: 'https://db2bjl98xr1b7.cloudfront.net',
            navDefinitionBaseUrl: 'https://d10f2sfhyq4iy8.cloudfront.net',
        },
        qa03: {
            memberUrl: `https://member.${env}.aws.${brand}.com`,
            communityUrl: `https://community.${env}.aws.${brand}.com/rms`,
            sessionsUrl: 'https://qa.sessions.psychologytoday.com/dashboard',
            copyBaseUrl: 'https://di3km12qxcyea.cloudfront.net',
            navDefinitionBaseUrl: 'https://d2jsxzv58u9j7z.cloudfront.net',
        },
        prod: {
            memberUrl: `https://member.${brand}.com`,
            communityUrl: `https://community.${brand}.com/rms`,
            sessionsUrl: 'https://sessions.psychologytoday.com/dashboard',
            copyBaseUrl: 'https://d35kml8zmh6y3i.cloudfront.net',
            navDefinitionBaseUrl: 'https://d3r3iytm0122ax.cloudfront.net',
        },
    };

    return mapping[shortEnv];
};

const defaultConfig = {
    env: '',
    hasSessions: false,
    nav: '',
    path: '',
    memberUrl: '',
    communityUrl: '',
    sessionUrl: '',
    copyBaseUrl: '',
    navDefinitionBaseUrl: '',
    navDefinition: {},
    copy: [],
    previewMode: false,
    isAdmin: false,
    brand: '', // always required
    viewingContext: '',
    viewingCountryCode: '', // always required
    viewingLanguageCode: '', // always required
    profileStatus: '',
    profileHealthRole: '',
    profileId: '',
    variables: {},
};

function getNav(config) {
    config = setDefaults(config);
    validate(config);

    return render(config);
}

function getNavAsync(config) {
    config = setDefaults(config);

    try {
        validate(config);
    } catch (e) {
        return Promise.reject(e);
    }

    return Promise.resolve()
        .then(function () {
            // fetch or return copy
            if (config.copy.length < 1) {
                return getCopyGroup({
                    copyUrl: envToBaseURLs({ env: config.env, brand: config.brand }).copyBaseUrl,
                    groupName: config.nav,
                    brand: config.brand,
                    context: config.viewingContext,
                    previewMode: config.previewMode,
                    country: config.viewingCountryCode,
                    language: config.viewingLanguageCode,
                });
            } else {
                return config.copy;
            }
        })
        .then(function (copy) {
            // add copy to config
            config.copy = copy;
        })
        .then(function () {
            // fetch or return nav
            if (Object.keys(config.navDefinition).length === 0) {
                return getNavDefinition({
                    navDefinitionUrl: envToBaseURLs({ env: config.env, brand: config.brand })
                        .navDefinitionBaseUrl,
                    navDefinition: config.nav,
                });
            } else {
                return config.navDefinition;
            }
        })
        .then(function (navDefinition) {
            // add nav config
            config.navDefinition = navDefinition;
        })
        .then(function () {
            // render
            return render(config);
        })
        .then(function (renderedNav) {
            // filter by path
            if (config.path != '') {
                return filterNav(renderedNav, config.path.split('.'));
            } else {
                return renderedNav;
            }
        });
}

function filterNav(renderedNav, pathStack) {
    let filteredNav = [];

    if (pathStack.length === 0) {
        return [];
    }

    let at = pathStack.shift();

    for (let i = 0; i < renderedNav.length; i++) {
        let navItem = renderedNav[i];
        if (navItem.id === at) {
            if (pathStack.length === 0) {
                return [navItem];
            } else {
                return filterNav(navItem.children, pathStack);
            }
        }
    }

    return filteredNav;
}

function validate(config) {
    if (
        config.env === '' ||
        (!['qa02', 'qa03', 'prod'].includes(config.env) && !config.env.startsWith('dev'))
    ) {
        throw new Error('Must provide config.env (dev<username>01,qa02,qa03,prod)');
    }

    if (config.brand === '') {
        throw new Error('Must provide config.brand');
    }

    if (config.viewingCountryCode === '') {
        throw new Error('Must provide config.viewingCountryCode');
    }

    if (config.viewingLanguageCode === '') {
        throw new Error('Must provide config.viewingLanguageCode');
    }

    if (config.copy.length < 1 && config.copyBaseUrl != '' && config.viewingContext === '') {
        throw new Error('Must provide config.viewingContext when config.copy is not set');
    }

    if (config.copy.length < 1 && config.copyBaseUrl != '' && config.nav === '') {
        throw new Error('Must provide config.nav when config.copy is not set');
    }

    if (
        Object.keys(config.navDefinition).length === 0 &&
        config.navDefinitionBaseUrl != '' &&
        config.nav === ''
    ) {
        throw new Error('Must provide config.nav when config.navDefinition is not set');
    }

    return;
}

function setDefaults(config = {}) {
    return { ...defaultConfig, ...config };
}

function render(config) {
    const defaultLanguages = config.navDefinition['default_languages'];
    const navigations = config.navDefinition['navigations'];
    const baseUrls = envToBaseURLs({ env: config.env, brand: config.brand });

    let renderedNav = [];
    let variables = {
        MEMBER_URL: baseUrls.memberUrl,
        COMMUNITY_URL: baseUrls.communityUrl,
        SESSIONS_URL: baseUrls.sessionsUrl,
        PROFILE_ID: config.profileId,
        LOCALE: getMemberLocaleVariable(
            config.viewingCountryCode,
            config.viewingLanguageCode,
            defaultLanguages
        ),
    };

    // Add variables passed in
    variables = Object.assign(variables, config.variables);

    const copyIndex = createCopyIndex(config.copy, variables);

    navigations.forEach(function (navItem) {
        // Check whitelist / blacklist
        if (shouldShow(navItem, config) === false) {
            return;
        }

        let rendered = renderNavigationItem(navItem, copyIndex, variables, config, '');
        if (Object.keys(rendered).length > 0) {
            renderedNav.push(rendered);
        }
    });

    return renderedNav;
}

function shouldShow(navItem, config) {
    let show = true;

    restrictors.forEach(function (property) {
        if (
            navItem[`${property.navProperty}_whitelist`] &&
            Array.isArray(navItem[`${property.navProperty}_whitelist`]) &&
            !navItem[`${property.navProperty}_whitelist`].includes(config[property.configVariable])
        ) {
            show = false;
        }
    });

    if (!show) {
        return false;
    }

    restrictors.forEach(function (property) {
        if (
            navItem[`${property.navProperty}_blacklist`] &&
            Array.isArray(navItem[`${property.navProperty}_blacklist`]) &&
            navItem[`${property.navProperty}_blacklist`].includes(config[property.configVariable])
        ) {
            show = false;
        }
    });

    if (!show) {
        return false;
    }

    return true;
}

function renderNavigationItem(navItem, copyIndex, variables, config, path) {
    let url = '';
    let pill = null;

    if (navItem.url) {
        url = renderUrl(navItem.url, variables, config.isAdmin);
    }

    if (navItem.pill) {
        pill = {};
        pill['label'] = copyIndex[navItem.pill['copy_key']];
        pill['copy_key'] = navItem.pill['copy_key'];
        pill['style'] = navItem.pill['style'];

        if (navItem.pill.url) {
            pill['url'] = renderUrl(navItem.pill.url, variables, config.isAdmin);
        }
    }

    let renderedItem = {
        id: navItem.id,
        path: path == '' ? navItem.id : path + '.' + navItem.id,
        copy_key: navItem['copy_key'],
        label: copyIndex[navItem['copy_key']],
    };

    if (url === '' && navItem['hide_if_url_empty']) {
        return {};
    }

    if (url != '') {
        renderedItem['url'] = url;
    }

    if (pill) {
        renderedItem['pill'] = pill;
    }

    if (navItem.children) {
        let children = [];
        navItem.children.forEach(function (child) {
            // Check whitelist / blacklist
            if (shouldShow(child, config) === false) {
                return;
            }

            let rendered = renderNavigationItem(
                child,
                copyIndex,
                variables,
                config,
                renderedItem.path
            );
            if (Object.keys(rendered).length > 0) {
                children.push(rendered);
            }
        });

        if (children.length > 0) {
            renderedItem.children = children;
        }
    }

    return renderedItem;
}

function renderUrl(urlObject, variables, isAdmin) {
    let baseUrl = urlObject.base_url;

    // Handle query params if provided
    let params = urlObject.params || {};
    let adminParams = urlObject.admin_params && isAdmin ? urlObject.admin_params : {};

    if (Object.entries(params).length > 0 || Object.entries(adminParams).length) {
        let allParams = Object.assign(params, adminParams);
        let allParamsRendered = {};
        Object.entries(allParams).forEach(function (entry) {
            const [key, value] = entry;
            allParamsRendered[key] = replaceVariables(value, variables);
        });
        baseUrl += '?' + querystring.stringify(allParamsRendered);
    }

    return replaceVariables(baseUrl, variables);
}

function createCopyIndex(copySet, variables) {
    let copyIndex = {};
    copySet.forEach(function (item) {
        copyIndex[item.key] = replaceVariables(item.value, variables);
    });

    return copyIndex;
}

function replaceVariables(template, data) {
    const pattern = /\[(\w+?)\]/g; // [property]
    return template.replace(pattern, (_, token) => data[token] || '');
}

function getMemberLocaleVariable(viewingCountryCode, viewingLanguageCode, defaultLanguages) {
    let locale = viewingLanguageCode + '-' + viewingCountryCode;

    if (viewingLanguageCode.toLowerCase() == defaultLanguages[viewingCountryCode.toUpperCase()]) {
        locale = viewingCountryCode;
    }

    return locale.toLowerCase();
}

function getCopyGroup({
    copyUrl = '',
    groupName = '',
    brand = '',
    context = '',
    previewMode = false,
    country = '',
    language = '',
}) {
    return new Promise(async (resolve, reject) => {
        if (copyUrl === '') {
            reject(new Error('no copyUrl provided'));
        }

        let state = previewMode ? 'draft' : 'published';
        let filePath = [groupName, brand, context, state, country, language]
            .join('/')
            .toLowerCase()
            .concat('.json');
        let requestUrl = `${copyUrl}/${filePath}`;

        try {
            const copy = await axios.get(requestUrl);

            let { data: copyData = {} } = copy || {};

            resolve(copyData);
        } catch (e) {
            reject(new Error(e.message + '.  GET ' + requestUrl));
        }
    });
}

function getNavDefinition({ navDefinitionUrl = '', navDefinition = '' }) {
    return new Promise(async (resolve, reject) => {
        let filePath = [navDefinition].join('/').toLowerCase().concat('.json');
        let requestUrl = `${navDefinitionUrl}/${filePath}`;

        try {
            const nav = await axios.get(requestUrl);

            let { data: navData = {} } = nav || {};

            resolve(navData);
        } catch (e) {
            reject(new Error(e.message + '.  GET ' + requestUrl));
        }
    });
}

export { getNavAsync as default, getNav, filterNav };
