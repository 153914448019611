import styled from "styled-components";

const Label = styled.label`
  color: ${({ theme, disabled, error }) =>
    disabled
      ? "rgba(44, 45, 48, 0.75)"
      : error
      ? theme.colors.red
      : theme.colors.default};
  display: block;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-weight: normal;
  margin-bottom: 3px;
  text-align: left;
`;

export default Label;
